(async function () {
    //fetch the data attributes from the article teaser
    const uuid = document.querySelector('.trackable-plusallt-article-teaser').getAttribute('data-article-uuid');    
    const articleUrl = document.querySelector('.trackable-plusallt-article-teaser').getAttribute('data-article-url');
    const headline = document.querySelector('.trackable-plusallt-article-teaser').getAttribute('data-article-headline');
    const articleImage = document.querySelector('.trackable-plusallt-article-teaser').getAttribute('data-article-image-url');
    const articleSiteTitle = document.querySelector('.trackable-plusallt-article-teaser').getAttribute('data-article-site');

    //create a div element and add the fetched data attributes to the div
    const articleTeaser = document.createElement('div');
    articleTeaser.innerHTML = `
        <div class="container card news-card" id=${uuid}>
            <a class="trackable-plusallt-article-teaser" href="${articleUrl}">
                <div class="row card-header bg-white align-items-center d-flex border-0">
                    <div class="col-auto">
                        <img src="https://www.plusallt.se/assets/images/brands/barometern-GIFJWAQ4.svg" class="news-logo" width="30" height="20">
                    </div>
                    <div class="col">
                        <h6 class="card-title mb-0 font-weight-bold">Exklusivt för dig med +allt. Innehåll från Barometern.</h6>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-md-6 p2 w-50">
                        <h5 class="card-title">${headline}</h5>
                    </div> 
                    <div class="col-md-6 p2 image-container w-50">
                        <img class="img-fluid bg-image" src="${articleImage}" alt="Barometern-OT">
                        <img class="img-fluid logo-image" src="https://bn-plusallt-button-prod.bnlo.bn.nr/assets/96f2791ed1ae4a42c391.svg" alt="Plusallt">
                    </div>
                </div>
            </a>
        </div>
    `;
    
    //append the created div to the body
    document.body.innerHTML = '';
    document.body.append(articleTeaser);
    
    //add bootstrap stylesheet to head
    const head = document.head;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css';
    head.appendChild(link);
    
    //append bootstrap script
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js';
    document.body.appendChild(script);
})();
